import React, { useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import heic2any from 'heic2any';
import JSZip from 'jszip';
import './App.css';
import logo from './assets/logo.svg';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import LazyImage from './components/LazyImage';

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB

function formatFileSize(bytes: number): string {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

// Вспомогательные функции
const isHeicFile = (file: File): boolean => {
  return file.type === 'image/heic' || file.name.toLowerCase().endsWith('.heic');
};

const generatePreview = async (file: File, quality: number = 0.3): Promise<string> => {
  try {
    if (isHeicFile(file)) {
      const blob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality
      });
      const previewBlob = Array.isArray(blob) ? blob[0] : blob;
      return URL.createObjectURL(previewBlob);
    }
    return URL.createObjectURL(file);
  } catch (error) {
    console.error(`Error generating preview for ${file.name}:`, error);
    throw error;
  }
};

const validateFileSize = (file: File): boolean => {
  if (file.size > MAX_FILE_SIZE) {
    console.warn(`File ${file.name} (${formatFileSize(file.size)}) exceeds maximum size of ${formatFileSize(MAX_FILE_SIZE)}`);
    return false;
  }
  return true;
};

const FAQ = () => {
  const [openItem, setOpenItem] = useState<number | null>(null);

  const faqItems = [
    {
      question: "What is HEIC format and why do I need to convert it?",
      answer: "HEIC (High Efficiency Image Container) is a modern image format used by Apple on iOS devices. While it provides better compression while maintaining quality, many devices and programs don't support it. Converting to JPEG makes your photos compatible with all devices and platforms."
    },
    {
      question: "Is it safe to upload my photos to your converter?",
      answer: "Yes, it's completely safe. Our converter works entirely in your browser, which means your files are never uploaded to any server. All processing happens locally on your device, ensuring maximum privacy and security."
    },
    {
      question: "What's the maximum quality I can get from conversion?",
      answer: "Our converter allows you to adjust the conversion quality up to 100%, providing the highest possible quality for the output JPEG file. The default setting is 90%, which offers an excellent balance between quality and file size."
    },
    {
      question: "How many files can I convert at once?",
      answer: "You can convert multiple HEIC files simultaneously. Each file has a size limit of 50MB. For optimal performance, we recommend converting no more than 20 files at a time."
    },
    {
      question: "Will I lose quality when converting from HEIC to JPEG?",
      answer: "When converting at maximum quality (100%), quality loss will be minimal. However, since JPEG uses a different compression algorithm, some slight quality loss is inevitable. For most applications, this difference is imperceptible to the human eye."
    },
    {
      question: "Are photo metadata preserved during conversion?",
      answer: "Yes, our converter preserves important image metadata, including EXIF data (capture date, camera model, shooting settings). This is particularly important for photographers and those who want to maintain information about their shots."
    },
    {
      question: "Can I use the converter on mobile devices?",
      answer: "Yes, our converter is fully responsive and works on all modern mobile devices. You can upload and convert files directly from your smartphone or tablet."
    },
    {
      question: "How do I download the converted images?",
      answer: "After conversion is complete, you can download all images as a single ZIP archive or download each image individually. Files are automatically named in a clear way, preserving the original filenames."
    }
  ];

  return (
    <section className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-container">
        {faqItems.map((item, index) => (
          <article 
            key={index} 
            className="faq-item"
            itemScope 
            itemType="https://schema.org/Question"
          >
            <button
              className={`faq-question ${openItem === index ? 'active' : ''}`}
              onClick={() => setOpenItem(openItem === index ? null : index)}
              aria-expanded={openItem === index}
            >
              <span itemProp="name">{item.question}</span>
              <span className="faq-icon">{openItem === index ? '−' : '+'}</span>
            </button>
            {openItem === index && (
              <div 
                className="faq-answer"
                itemScope 
                itemType="https://schema.org/Answer"
              >
                <div itemProp="text">{item.answer}</div>
              </div>
            )}
          </article>
        ))}
      </div>
    </section>
  );
};

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logo} alt="HEIC2JPEG Converter" className="logo-image" />
        </div>
        <div className="footer-text">
          Made with <span className="heart">❤️</span> by Smag
        </div>
        <div className="footer-links">
          <a href="/privacy" className="footer-link">Privacy Policy</a>
          <a href="/terms" className="footer-link">Terms of Use</a>
        </div>
      </div>
    </footer>
  );
};

function App() {
  const [isConverting, setIsConverting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState<Array<{ id: string; file: File }>>([]);
  const [quality, setQuality] = useState(0.9);
  const [convertedFiles, setConvertedFiles] = useState<Blob[]>([]);
  const [previews, setPreviews] = useState<Array<{ 
    id: string;
    url: string; 
    loading: boolean; 
    error: string | null 
  }>>([]);
  const [compress, setCompress] = useState(false);

  // Добавляем эффект для синхронизации состояния
  useEffect(() => {
    if (files.length > 20) {
      const slicedFiles = files.slice(0, 20);
      setFiles(slicedFiles);
      setPreviews(prev => prev.slice(0, 20));
      console.warn('Files were automatically limited to 20');
    }
  }, [files]);

  const compressImage = async (blob: Blob): Promise<Blob> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(blob);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;
        
        let width = img.width;
        let height = img.height;
        
        if (compress && (width > 1920 || height > 1080)) {
          if (width / height > 1920 / 1080) {
            width = 1920;
            height = Math.round(1920 * img.height / img.width);
          } else {
            height = 1080;
            width = Math.round(1080 * img.width / img.height);
          }
        }
        
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        
        canvas.toBlob(
          (resultBlob) => {
            URL.revokeObjectURL(img.src);
            resolve(resultBlob as Blob);
          },
          'image/jpeg',
          quality
        );
      };
    });
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    // Фильтруем файлы по размеру
    const oversizedFiles = acceptedFiles.filter(file => !validateFileSize(file));
    if (oversizedFiles.length > 0) {
      const fileList = oversizedFiles
        .map(file => `\n- ${file.name} (${formatFileSize(file.size)})`)
        .join('');
      alert(`The following files exceed the maximum size of ${formatFileSize(MAX_FILE_SIZE)}:${fileList}`);
      
      // Оставляем только файлы подходящего размера
      acceptedFiles = acceptedFiles.filter(validateFileSize);
      if (acceptedFiles.length === 0) return;
    }

    const totalFiles = files.length + acceptedFiles.length;
    if (totalFiles > 20) {
      const remainingSlots = Math.max(0, 20 - files.length);
      alert(`Maximum number of files is 20. You can add ${remainingSlots} more files.`);
      // Берем только то количество файлов, которое можем добавить
      acceptedFiles = acceptedFiles.slice(0, remainingSlots);
      if (acceptedFiles.length === 0) return;
    }

    const newFiles = acceptedFiles.map(file => ({
      id: Math.random().toString(36).substr(2, 9),
      file
    }));

    const newPreviews = newFiles.map(({ id }) => ({
      id,
      url: '',
      loading: true,
      error: null
    }));
    
    setPreviews(prev => {
      const updatedPreviews = [...prev, ...newPreviews];
      return updatedPreviews.slice(0, 20);
    });

    setFiles(prev => {
      const updatedFiles = [...prev, ...newFiles];
      return updatedFiles.slice(0, 20);
    });

    setConvertedFiles([]);

    // Генерируем превью последовательно
    const generatePreviews = async () => {
      for (let i = 0; i < newFiles.length && i < (20 - files.length); i++) {
        const { id, file } = newFiles[i];
        
        try {
          const previewUrl = await generatePreview(file);
          setPreviews(prev => prev.map(p => 
            p.id === id ? { ...p, url: previewUrl, loading: false, error: null } : p
          ));
        } catch (error) {
          setPreviews(prev => prev.map(p => 
            p.id === id ? { ...p, url: '', loading: false, error: 'Failed to generate preview' } : p
          ));
        }
      }
    };

    generatePreviews();
  }, []);

  // Очистка URL при размонтировании
  useEffect(() => {
    return () => {
      previews.forEach(preview => {
        if (preview.url) {
          URL.revokeObjectURL(preview.url);
        }
      });
    };
  }, [previews]);

  const removeFile = (id: string) => {
    // Очищаем URL только для удаляемого файла
    const preview = previews.find(p => p.id === id);
    if (preview?.url) {
      URL.revokeObjectURL(preview.url);
    }
    
    setPreviews(prev => prev.filter(p => p.id !== id));
    setFiles(prev => prev.filter(f => f.id !== id));
    setConvertedFiles([]);
  };

  const handleConvert = async () => {
    setIsConverting(true);
    setProgress(0);

    const zip = new JSZip();
    const converted: Blob[] = [];

    try {
      // Убеждаемся, что обрабатываем только первые 20 файлов
      const filesToConvert = files.slice(0, 20);
      
      // Проверяем размеры файлов перед конвертацией
      const oversizedFiles = filesToConvert.filter(({ file }) => !validateFileSize(file));
      if (oversizedFiles.length > 0) {
        const fileList = oversizedFiles
          .map(({ file }) => `\n- ${file.name} (${formatFileSize(file.size)})`)
          .join('');
        alert(`Cannot convert files that exceed ${formatFileSize(MAX_FILE_SIZE)}:${fileList}`);
        setIsConverting(false);
        return;
      }
      
      for (let i = 0; i < filesToConvert.length; i++) {
        const { file } = filesToConvert[i];
        if (isHeicFile(file)) {
          const blob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: quality
          });
          
          const jpegBlob = Array.isArray(blob) ? blob[0] : blob;
          const processedBlob = await compressImage(jpegBlob);
          converted.push(processedBlob);
          
          const fileName = file.name.replace(/\.heic$/i, '.jpg');
          zip.file(fileName, processedBlob);
        }
        setProgress(((i + 1) / filesToConvert.length) * 100);
      }
      setConvertedFiles(converted);
    } catch (error) {
      console.error('Conversion error:', error);
      alert('An error occurred while converting files');
    } finally {
      setIsConverting(false);
      setProgress(0);
    }
  };

  const handleDownload = async () => {
    try {
      if (convertedFiles.length === 1) {
        const url = URL.createObjectURL(convertedFiles[0]);
        const link = document.createElement('a');
        link.href = url;
        link.download = files[0].file.name.replace(/\.heic$/i, '.jpg');
        link.click();
        URL.revokeObjectURL(url);
      } else if (convertedFiles.length > 1) {
        const zip = new JSZip();
        convertedFiles.forEach((blob, index) => {
          const fileName = files[index].file.name.replace(/\.heic$/i, '.jpg');
          zip.file(fileName, blob);
        });
        
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        const url = URL.createObjectURL(zipBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'converted_images.zip';
        link.click();
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Download error:', error);
      alert('An error occurred while downloading files');
    }
  };

  const handleQualityChange = (newQuality: number) => {
    setQuality(newQuality);
  };

  const handleCompressToggle = (newCompress: boolean) => {
    setCompress(newCompress);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/heic': ['.heic', '.HEIC'],
    },
  });

  return (
    <Router>
      <Routes>
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/" element={
          <div className="container">
            <nav className="navbar">
              <a href="/" className="logo">
                <img src={logo} alt="HEIC2JPEG Converter Logo" className="logo-image" />
              </a>
            </nav>

            <main>
              <section className="hero-section">
                <h1 className="hero-title">Convert HEIC to JPG Online</h1>
                <p className="hero-subtitle">
                  Convert your iPhone HEIC photos to universally compatible JPG format instantly.<br />
                  No registration required, no watermarks, completely free!
                </p>
              </section>

              <section className="upload-section">
                <div
                  {...getRootProps()}
                  className={`dropzone ${isDragActive ? 'active' : ''}`}
                  role="button"
                  aria-label="Drop zone for uploading HEIC files"
                >
                  <input {...getInputProps()} aria-label="File input" />
                  <p>Drag & drop HEIC files here, or click to select files</p>
                  <p className="hint">Upload up to 20 files at once (max {formatFileSize(MAX_FILE_SIZE)} per file)</p>
                </div>
              </section>

              <section className="preview-section" aria-label="Image previews">
                <div className="preview-grid">
                  {previews.map((preview) => (
                    <div key={preview.id} className="preview-item">
                      {preview.loading ? (
                        <div className="preview-loading">
                          <div className="spinner" role="status" aria-label="Loading..." />
                        </div>
                      ) : preview.error ? (
                        <div className="preview-error" role="alert">{preview.error}</div>
                      ) : (
                        <>
                          <LazyImage 
                            src={preview.url}
                            alt={`Preview of image ${files.find(f => f.id === preview.id)?.file.name || ''}`}
                            className="preview-image"
                          />
                          <button 
                            onClick={() => removeFile(preview.id)} 
                            className="remove-button"
                            aria-label={`Remove ${files.find(f => f.id === preview.id)?.file.name || 'image'}`}
                          >
                            ×
                          </button>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </section>

              {files.length > 0 && (
                <div className="settings">
                  <div className="quality-control">
                    <label>
                      Quality:
                      <input
                        type="range"
                        min="0.1"
                        max="1"
                        step="0.1"
                        value={quality}
                        onChange={(e) => handleQualityChange(parseFloat(e.target.value))}
                      />
                      <span>{Math.round(quality * 100)}%</span>
                    </label>
                  </div>
                  <div className="compress-control">
                    <label>
                      <input
                        type="checkbox"
                        checked={compress}
                        onChange={(e) => handleCompressToggle(e.target.checked)}
                      />
                      Compress (max. 1920x1080)
                    </label>
                  </div>
                </div>
              )}

              {files.length > 0 && (
                <div className="controls">            
                  <button 
                    className="convert-button"
                    onClick={handleConvert}
                    disabled={isConverting}
                  >
                    {isConverting ? (
                      <div className="converting">
                        <span>Converting... {Math.round(progress)}%</span>
                        <div className="progress-bar">
                          <div className="progress" style={{ width: `${progress}%` }}></div>
                        </div>
                      </div>
                    ) : (
                      'Convert'
                    )}
                  </button>

                  {convertedFiles.length > 0 && (
                    <button 
                      className="download-button"
                      onClick={handleDownload}
                    >
                      Download
                    </button>
                  )}
                </div>
              )}
              
              <section className="seo-section">
                <div>
                  <h2>Free HEIC to JPG Converter</h2>
                  <p>
                    Professional online HEIC to JPG converter with advanced features and secure processing directly in your browser.
                  </p>
                  <h3>Key Features of Our Converter</h3>
                  <ul>
                    <li><strong>Batch Processing:</strong> ✨ Convert multiple HEIC files to JPG at once</li>
                    <li><strong>Privacy First:</strong> 🔒 100% secure - all processing happens in your browser</li>
                    <li><strong>iOS Compatible:</strong> 📱 Perfect for iPhone and iPad HEIC photos</li>
                    <li><strong>Bulk Download:</strong> 💾 Batch convert and download as ZIP</li>
                    <li><strong>Quality Control:</strong> 🎨 Adjustable quality settings</li>
                    <li><strong>Fast Processing:</strong> ⚡ Lightning-fast conversion</li>
                  </ul>
                  <h3>About HEIC Format</h3>
                  <p>
                    HEIC (High Efficiency Image Container) is Apple's default photo format, introduced to save storage space while maintaining high image quality. 
                    While HEIC offers excellent compression, many programs and websites don't support it yet. 
                    Our converter helps you quickly transform HEIC files into the widely-supported JPG format while preserving image quality.
                  </p>
                </div>
              </section>

              <FAQ />
              <Footer />
            </main>
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;
