import React, { useState, useEffect, useRef } from 'react';
import './LazyImage.css';

interface LazyImageProps {
    src: string;
    alt: string;
    className?: string;
}

const LazyImage: React.FC<LazyImageProps> = ({ src, alt, className = '' }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isInView, setIsInView] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsInView(true);
                    observer.disconnect();
                }
            },
            {
                rootMargin: '50px',
                threshold: 0.01
            }
        );

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    const handleLoad = () => {
        setIsLoaded(true);
    };

    return (
        <div className={`lazy-image-container ${className}`} ref={imgRef}>
            {isInView && (
                <>
                    <img
                        src={src}
                        alt={alt}
                        className={`lazy-image ${isLoaded ? 'loaded' : ''}`}
                        onLoad={handleLoad}
                        loading="lazy"
                    />
                    {!isLoaded && <div className="lazy-image-placeholder" />}
                </>
            )}
        </div>
    );
};

export default LazyImage;
