import React from 'react';
import '../App.css';

const TermsOfUse = () => {
  return (
    <div className="container">
      <div className="policy-content">
        <h1>Terms of Use</h1>
        <p>Last updated: {new Date().toLocaleDateString()}</p>

        <section>
          <h2>Acceptance of Terms</h2>
          <p>
            By accessing and using HEIC2JPEG Converter, you accept and agree to be bound by the terms and provisions of this agreement.
          </p>
        </section>

        <section>
          <h2>Service Description</h2>
          <p>
            HEIC2JPEG Converter is a free online service that allows users to convert HEIC image files to JPEG format. The service operates entirely within your web browser.
          </p>
        </section>

        <section>
          <h2>Use Restrictions</h2>
          <p>
            When using our service, you agree to the following:
          </p>
          <ul>
            <li>Maximum file size per image: 50MB</li>
            <li>Maximum number of files per conversion: 20</li>
            <li>You will not attempt to circumvent any service limitations</li>
            <li>You will not use the service for any unlawful purpose</li>
            <li>You will not upload malicious files or attempt to compromise the service</li>
          </ul>
        </section>

        <section>
          <h2>Intellectual Property</h2>
          <p>
            The service, including its original content, features, and functionality, is owned by HEIC2JPEG Converter and is protected by international copyright, trademark, and other intellectual property laws.
          </p>
        </section>

        <section>
          <h2>Disclaimer of Warranties</h2>
          <p>
            The service is provided "as is" and "as available" without any warranties of any kind, either express or implied, including but not limited to:
          </p>
          <ul>
            <li>The service will be uninterrupted or error-free</li>
            <li>The results of using the service will meet your requirements</li>
            <li>The quality of any products, services, information, or other material obtained through the service will meet your expectations</li>
          </ul>
        </section>

        <section>
          <h2>Limitation of Liability</h2>
          <p>
            In no event shall HEIC2JPEG Converter be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.
          </p>
        </section>

        <section>
          <h2>File Processing</h2>
          <p>
            All file processing occurs locally in your browser. We do not:
          </p>
          <ul>
            <li>Upload your files to any server</li>
            <li>Store your files or converted images</li>
            <li>Access your files after processing is complete</li>
          </ul>
        </section>

        <section>
          <h2>Changes to Terms</h2>
          <p>
            We reserve the right to modify these terms at any time. We will notify users of any changes by updating the date at the top of this page.
          </p>
        </section>

        <section>
          <h2>Contact Information</h2>
          <p>
            For any questions regarding these Terms of Use, please contact us at support@heic2jpeg.com
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfUse;
