import React from 'react';
import '../App.css';

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="policy-content">
        <h1>Privacy Policy</h1>
        <p>Last updated: {new Date().toLocaleDateString()}</p>

        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to HEIC2JPEG Converter. We respect your privacy and are committed to protecting any information that might be collected through our service.
            This Privacy Policy explains our practices regarding data collection and usage.
          </p>
        </section>

        <section>
          <h2>Information We Don't Collect</h2>
          <p>
            Our service operates entirely in your browser. This means:
          </p>
          <ul>
            <li>Your files are never uploaded to any server</li>
            <li>All image conversion happens locally on your device</li>
            <li>We don't store any of your images or personal information</li>
            <li>We don't track individual user behavior</li>
          </ul>
        </section>

        <section>
          <h2>Technical Information</h2>
          <p>
            We may collect anonymous usage statistics through standard web analytics tools to improve our service. This includes:
          </p>
          <ul>
            <li>Browser type and version</li>
            <li>Operating system</li>
            <li>Page views and general usage patterns</li>
          </ul>
          <p>
            This information is collected anonymously and cannot be traced back to individual users.
          </p>
        </section>

        <section>
          <h2>Local Storage</h2>
          <p>
            Our service may use your browser's local storage to improve user experience. This data never leaves your device and is entirely under your control.
          </p>
        </section>

        <section>
          <h2>Third-Party Services</h2>
          <p>
            We don't integrate with any third-party services that would collect your personal information or process your files.
          </p>
        </section>

        <section>
          <h2>Children's Privacy</h2>
          <p>
            Our service is available to users of all ages. Since we don't collect any personal information, no special provisions for children's privacy are necessary.
          </p>
        </section>

        <section>
          <h2>Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
          </p>
        </section>

        <section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at support@heic2jpeg.com
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
